module.exports = {
  PricingData: [
    {
      title: "Free",
      features: ["Easy Installations", "Unlimited support", "Free Forever"],
      price: 0,
    },
    {
      title: "Medium",
      features: ["Easy Installations", "Unlimited support", "Free Forever"],
      price: 99,
    },
    {
      title: "Business",
      features: ["Easy Installations", "Unlimited support", "Free Forever"],
      price: 999,
    },
    {
      title: "Free",
      features: ["Easy Installations", "Unlimited support", "Free Forever"],
      price: 0,
    },
  ],

  ExpertData: [
    {
      photo: "/assets/images/event/l3-2.png",
      title: "Sam Rowling",
      details: "Team Leader - otstrab",
    },
    {
      photo: "/assets/images/event/l3-3.png",
      title: "Mark Tucker",
      details: "App Developer - Jumpster",
    },
    {
      photo: "/assets/images/event/l3-4.png",
      title: "Sam Rowling",
      details: "Team Leader - otstrab",
    },
    {
      photo: "/assets/images/event/l3-3.png",
      title: "Mark Tucker",
      details: "App Developer - Jumpster",
    },
  ],

  TestimonialData: [
    {
      photo: "/assets/images/testimonial/user.png",
      name: "Amit Jain(CS)",
      role: "MD, Kkart India",
      details: "...",
    },
    {
      photo: "/assets/images/testimonial/user.png",
      name: "Prajkta Thakare",
      role: "Project Manager",
      details: "...",
    },
    {
      photo: "/assets/images/testimonial/user.png",
      name: "Sujeet Upare",
      role: "CEO",
      details: "...",
    },
    {
      photo: "/assets/images/testimonial/user.png",
      name: "Rakesh",
      role: "Project Manager",
      details: "...",
    },
  ],

  BlogData: [
    {
      id: 1,
      photo: "/assets/images/agency/blog/blog-list-1.jpg",
      title: "Twice profit than before you",
      date: "June 19, 2019",
      place: "Phonics",
      details:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.text ever since the 1500s type specimen book ",
    },
    {
      id: 2,
      photo: "/assets/images/agency/blog/blog-list-2.jpg",
      title: "Twice profit than before you",
      date: "June 19, 2019",
      place: "Phonics",
      details:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.text ever since the 1500s type specimen book ",
    },
    {
      id: 3,
      photo: "/assets/images/agency/blog/blog-list-3.jpg",
      title: "Twice profit than before you",
      date: "June 19, 2019",
      place: "Phonics",
      details:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.text ever since the 1500s type specimen book ",
    },
    {
      id: 4,
      photo: "/assets/images/agency/blog/blog-list-1.jpg",
      title: "Twice profit than before you",
      date: "June 19, 2019",
      place: "Phonics",
      details:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.text ever since the 1500s type specimen book ",
    },
  ],
};
